import _ from 'lodash'

const updatePoint = (state, payload) => {
  const result = {
    ...state,
    point: payload
  }

  return _.isEqual(state.point, payload) ? state : result
}

export default updatePoint