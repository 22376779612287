import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Router } from 'react-router-dom'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';

import StateProvider, { initialState, reducer } from './store/store'

import * as serviceWorker from './serviceWorker'

import App from './App'
import history from './configs/history'
import client from './configs/configApollo'

const app = (
  <StateProvider initialState={initialState} reducer={reducer}>
    <BrowserRouter>
      <ApolloHooksProvider client={client}>
        <Router history={history} >
          <App />
        </Router>
      </ApolloHooksProvider>
    </BrowserRouter>
  </StateProvider>
)

ReactDOM.render(app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
