import _ from 'lodash'
import updatePoint from './updatePoint'
import { findNavigation, updateNavigation } from './navigation/navigation'

export const reducer = (state, { type, payload }) => {
  let result = {}

  switch (type) {
    case 'UPDATE_USER':
      result = {
        ...state,
        user: payload
      }

      return _.isEqual(state.user, payload) ? state : result

    case 'UPDATE_POINT':
      return updatePoint(state, payload)

    case 'UPDATE_NAVIGATION':
      return updateNavigation(state, payload)

    case 'FIND_NAVIGATION':
      return findNavigation(state, payload)

    default:
      return state
  }
}