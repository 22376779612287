import { StateProvider, useStateValue } from './state/state'
import { initialState } from "./state/initialState/initialState"
import { reducer } from "./state/reducer/reducer"

export default StateProvider
export {
  useStateValue,
  initialState,
  reducer
}
