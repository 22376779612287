import _ from 'lodash'

const findNavigation = (state, payload) => {
  const [, path] = _.split(payload, '/')
  const pathname = `/${path}`

  const findNav = _.find(state.navigation, ({ root }) => root === pathname)

  const navigation = state.navigation.map(options => {
    return ({
      ...options,
      isActive: options.id === _.get(findNav, 'id', 0) ? true : false
    })
  })

  return _.isEqual(state.navigation, navigation) ? state : { ...state, navigation }
}

export default findNavigation