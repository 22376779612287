import menu1 from '../../../assets/images/icon-menu-2-2.png'
import menu2 from '../../../assets/images/icon-menu-1-2.png'
import menu3 from '../../../assets/images/icon-menu-3.png'
import menu4 from '../../../assets/images/icon-menu-4.png'
import menu5 from '../../../assets/images/icon-account.png'

import menuactive1 from '../../../assets/images/icon-menu-2-1.png'
import menuactive2 from '../../../assets/images/icon-menu-1-1.png'
import menuactive3 from '../../../assets/images/icon-menu-3-active.png'
import menuactive4 from '../../../assets/images/icon-menu-4-active.png'
import menuactive5 from '../../../assets/images/icon-account-active.png'

export const navigation = [
  {
    id: 0,
    href: '/',
    root: '/',
    img: menu1,
    img_active: menuactive1,
    textTH: 'หน้าแรก',
    isActive: false
  },
  {
    id: 1,
    href: '/redeem',
    root: '/redeem',
    img: menu2,
    img_active: menuactive2,
    textTH: 'แลกของรางวัล',
    isActive: false
  },
  {
    id: 2,
    href: '/promotion',
    root: '/promotion',
    img: menu3,
    img_active: menuactive3,
    textTH: 'โปรโมชั่น',
    isActive: false
  },
  {
    id: 3,
    href: '/prompt',
    root: '/prompt',
    img: menu4,
    img_active: menuactive4,
    textTH: 'พร้อม พลัส',
    isActive: false
  },
  {
    id: 4,
    href: '/account',
    root: '/account',
    img: menu5,
    img_active: menuactive5,
    textTH: 'บัญชีผู้ใช้',
    isActive: false
  }
]