import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client'
// import { getMainDefinition } from '@apollo/client/utilities'
// import { WebSocketLink } from '@apollo/client/link/ws'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GRAPHQL_ENDPOINT_HTTPS, TOKEN } from './constant'

import history from "./history.jsx";


// const token = localStorage.getItem(TOKEN);

const httpLink = ApolloLink.from([
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) console.error(`[ 📡 Network error]: ${networkError}`);

    if (graphQLErrors) {
      graphQLErrors.map(({ message, status }) => {
        switch (message) {
          case "Invalid Authorization":

            localStorage.clear();
            history.push("/login");

            const headers = operation.getContext().headers;

            operation.setContext({
              headers: {
                ...headers,
                authorization: null
              }
            });

            return forward(operation);

          default:
            return null;
        }
      });
    }
  }),
  new createUploadLink({
    uri: GRAPHQL_ENDPOINT_HTTPS
    // credentials: 'same-origin'
  })
]);

const authLink = new setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

// Create a WebSocket link:
// const wsLink = new WebSocketLink({
//   uri: GRAPHQL_ENDPOINT_WSS,
//   options: {
//     reconnect: true,
//     connectionParams: {
//       authToken: token
//     }
//   }
// });

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   wsLink,
// );

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export default client;
