import _ from 'lodash'

const updateNavigation = (state, payload) => {

  const navigation = state.navigation.map(options => {
    return {
      ...options,
      isActive: options.id === payload
    }
  })

  return _.isEqual(state.navigation, navigation) ? state : { ...state, navigation }
}

export default updateNavigation